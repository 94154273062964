<template>
  <div v-if="hasResource('feature_asset_add')">
    <div class="add">
      <div
        class="cursor-pointer width-107 height-46 mg-b-20 text-back"
        @click="back"
      >
        <i class="iconfont fz-24 black">&#xe647;</i
        ><span class="back-text">返回</span>
      </div>
      <div class="title-content">新增推荐资产</div>
      <div style="display: flex">
        <div class="pd-r-64 dis-in-b">
          <div class="sub-title-content">筛选资产</div>
          <el-form :inline="true" ref="form" :model="form" :rules="rules">
            <el-form-item
              label="内部ID"
              prop="number"
              class="fw-500 number mg-r-34"
            >
              <el-input
                v-model="form.number"
                placeholder="输入编号检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item
              label="外部ID/名称"
              prop="idOrName"
              class="fw-500 mg-r-34 idOrName"
            >
              <el-input
                v-model="form.idOrName"
                placeholder="输入ID/名称检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item class="ml-40">
              <span
                class="handle-size cursor-pointer fw-bold serach-button"
                @click="search()"
                >查询</span
              >
              <span
                class="mg-l-50 handle-size cursor-pointer fw-bold serach-button"
                @click="cancel()"
                >清空</span
              >
            </el-form-item>
          </el-form>
          <el-table
            header-cell-class-name="table-head"
            cell-class-name="table-cell"
            :data="pageInfo.tableData"
            row-key="id"
            ref="tableData"
            v-loading="pageInfo.tableLoading"
          >
            <el-table-column
              v-for="col in pageInfo.tableColumns"
              :key="col.prop"
              :prop="col.prop"
              :label="col.label"
              :width="col.width"
              :min-width="col.minWidth"
              :align="col.align"
            >
              <template slot-scope="{ row }">
                <div v-if="col.prop == 'add'">
                  <i
                    class="iconfont cursor-pointer"
                    v-if="!row.isAdd"
                    @click="handleAdd(row)"
                    >&#xe668;</i
                  >
                  <span class="assets-add" v-if="row.isAdd">已添加</span>
                </div>
                <div v-else-if="col.prop == 'name'" class="assets-name">
                  {{ row.name }}
                </div>
                <div v-else-if="col.prop === 'preview'">
                  <img
                    :src="`${row.preview}?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1`"
                    v-if="row.preview"
                    class="max-height-64 max-width-64"
                  />
                </div>
                <div v-else>{{ row[col.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="pageInfo.tableData.length != 0"
            class="ta-r mg-t-10"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageNumber"
            :page-sizes="[10, 20, 50]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="sortList dis-in-b">
          <div class="sub-title-content">
            * 添加资产
            <span class="mg-l-12">{{ pageInfo.addAssetData.length }}</span>
          </div>
          <div class="explain">点击右下角确认按钮，将视为成功添加。</div>
          <el-table
            header-cell-class-name="table-head"
            cell-class-name="table-cell"
            :data="pageInfo.addAssetData"
            row-key="id"
            ref="addTable"
            v-loading="pageInfo.addLoading"
          >
            <el-table-column
              v-for="col in pageInfo.addColumns"
              :key="col.prop"
              :prop="col.prop"
              :label="col.label"
              :width="col.width"
              :min-width="col.minWidth"
              :align="col.align"
            >
              <template slot-scope="{ $index, row }">
                <div v-if="col.prop == 'del'" @click="handleDel($index)">
                  <i class="el-icon-delete assets-del-icon cursor-pointer"></i>
                </div>
                <div v-else-if="col.prop == 'name'" class="assets-name">
                  {{ row.name }}
                </div>
                <div v-else-if="col.prop === 'preview'">
                  <img
                    :src="`${row.preview}?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1`"
                    v-if="row.preview"
                    class="max-height-64 max-width-64"
                  />
                </div>
                <div v-else>{{ row[col.prop] }}</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="add-btn">
            <el-button
              @click="confirm"
              class="black-btn btn-s"
              v-loading="confirmLoading"
              >确 认</el-button
            >
            <el-button @click="back" class="white-btn btn-s">取 消</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../api/asset";
export default {
  components: {},
  data() {
    return {
      form: {
        number: null,
        idOrName: null,
        addAssetNumber: null,
        addAssetIdOrName: null,
      },
      rules: {
        number: [
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        idOrName: [
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        addAssetNumber: [
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        addAssetIdOrName: [
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      confirmLoading: false,
      pageInfo: {
        tableColumns: [
          {
            prop: "preview",
            label: "缩略图",
            width: 140,
            align: "left",
            minWidth: "30%",
          },
          {
            prop: "origin_id",
            label: "内部ID",
            width: 140,
            align: "left",
            minWidth: "35%",
          },
          {
            prop: "asset_uid",
            label: "外部ID",
            width: 160,
            align: "left",
            minWidth: "30%",
          },
          {
            prop: "name",
            label: "资产名称",
            width: 180,
            align: "left",
            minWidth: "35%",
          },
          { prop: "add", label: "添加", align: "left" },
        ],
        addColumns: [
          {
            prop: "preview",
            label: "缩略图",
            width: 140,
            align: "left",
            minWidth: "30%",
          },
          {
            prop: "origin_id",
            label: "内部ID",
            width: 140,
            align: "left",
            minWidth: "35%",
          },
          {
            prop: "asset_uid",
            label: "外部ID",
            width: 180,
            align: "left",
            minWidth: "30%",
          },
          {
            prop: "name",
            label: "资产名称",
            width: 180,
            align: "left",
            minWidth: "30%",
          },
          { prop: "del", label: "删除", align: "left" },
        ],
        tableLoading: false,
        addLoading: false,
        tableData: [],
        addAssetData: [],
        featureIdList: [],
      },
    };
  },
  methods: {
    back() {
      this.$router.push("/operations/featured-items");
    },
    search() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.pageNumber = 1;
          this.assetList();
        }
      });
    },
    cancel() {
      this.form.number = null;
      this.form.idOrName = null;
      this.assetList();
    },
    getFeatureList() {
      let data = {
        market: "US",
        limit: -1,
        page: 1,
      };
      api.recAssetList(data).then((res) => {
        if (res.data.code === 10200) {
          let featureList = res.data.data.items;
          var featureIdList = featureList.map((item, index) => {
            return item.id;
          });
          this.featureIdList = featureIdList || [];
        }
      });
    },
    assetList() {
      let data = {
        limit: this.pageSize,
        order_by: "",
        page: this.pageNumber,
        filter: {
          origin_id: this.form.number,
          name_id: this.form.idOrName,
          status: "5",
        },
        search: "",
        market: "US",
      };
      api.recAssetFilterList(data).then((resp) => {
        if (resp.data.code == 10200) {
          this.pageInfo.tableData = resp.data.data.items;
          this.total = parseInt(resp.data.data.total);
          this.handleAddText();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.assetList();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.assetList();
    },
    handleAdd(row) {
      if (
        this.pageInfo.addAssetData.length != 0 &&
        this.pageInfo.addAssetData.filter((ele) => {
          return ele.id == row.id;
        }).length > 0
      ) {
        this.$$error("该资产已选择啦~");
        return;
      } else {
        this.pageInfo.addAssetData.push(row);
        this.$set(row, "isAdd", true);
      }
    },
    handleDel(index) {
      let data = this.pageInfo.addAssetData[index];
      this.pageInfo.addAssetData.splice(index, 1);
      this.pageInfo.tableData.forEach((element) => {
        if (element.id == data.id) {
          this.$set(element, "isAdd", false);
        }
      });
    },
    confirm() {
      if (this.pageInfo.addAssetData.length == 0) {
        this.$$error("请选择需要推荐的资产");
        return;
      }
      this.confirmLoading = true;
      let idArr = [];
      (this.pageInfo.addAssetData || []).forEach((element) => {
        idArr.push(element.id);
      });
      let data = {
        market: "US",
        ids: idArr,
      };
      api.addRecAssets(data).then((res) => {
        this.confirmLoading = false;
        if (res.data.code === 10200) {
          this.pageInfo.addAssetData = [];
          this.$$success("操作成功");
          this.back();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleAddText() {
      var addAsset = this.pageInfo.addAssetData.map((item, index) => {
        return item.id;
      });
      this.pageInfo.tableData.forEach((element) => {
        let isAdd =
          addAsset.indexOf(element.id) > -1 ||
          this.featureIdList.indexOf(element.id) > -1
            ? true
            : element.isAdd;
        this.$set(element, "isAdd", isAdd);
      });
    },
  },
  mounted() {
    this.getFeatureList();
  },
};
</script>
<style lang="less">
@import "index.less";
</style>
