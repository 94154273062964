import axios from '../uitls/require'

export default {
    getLocationInfo() {
        return axios.get('/locations')
    },
    //资产分类树型
    getCategoryTree(data) {
        return axios.get('/admin/category/tree?market=' + data);
    },
    //新增资产
    addAssets(data) {
        return axios.post('/admin/category/create', data);
    },
    // 删除资产
    delAsset(data) {
        return axios.post('/admin/category/delete', data);
    },
    //修改资产数据
    updataAsset(data) {
        return axios.post('/zh-CN/admin/category/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    //修改资产排序
    assetSort(data) {
        return axios.post('/zh-CN/admin/category/asset-sort', data);
    },
    // 获取资产分类具体内容
    getCategoryInfo(data) {
        return axios.get('/admin/category?market=' + data.market + '&id=' + data.id);
    },
    //资产审批的列表
    reviewList(data) {
        return axios.post('/admin/asset/reviews', data);
    },
    reviewInfo(data) {
        return axios.get('/admin/asset/review?market=' + data.market + '&id=' + data.id);
    },
    agreeAssets(data) {
        return axios.post('/admin/asset/review-agree', data);
    },
    rejectAssets(data) {
        return axios.post('/admin/asset/review-reject', data);
    },
    closeAsset(data) {
        return axios.post('/admin/asset/review-close', data);
    },
    // 重新发布资产
    resetAssets(data) {
        return axios.post('/admin/asset/review-resend', data);
    },
    assetHistory(id, data) {
        return axios.get('/admin/asset/review-history?id=' + id + '&market=' + data);
    },
    /**
     * 推荐资产
     */
    //推荐资产列表
    recAssetList(data) {
        return axios.post('/admin/feature/list', data);
    },
    // 新增推荐资产
    addRecAssets(data) {
        return axios.post('/zh-CN/admin/feature/asset-create', data);
    },
    // 删除推荐资产
    delRecAssets(data) {
        return axios.post('/zh-CN/admin/feature/asset-delete', data);
    },
    // 推荐资产排序
    sortRecAssets(data) {
        return axios.post('/zh-CN/admin/feature/asset-sort', data);
    },
    recAssetsImage(data) {
        return axios.post('/zh-CN/admin/feature/asset-upload', data);
    },

    /**
     * 推荐分类
     */
    recClassList(data) {
        return axios.post('/admin/collection/list', data);
    },
    recClassInfo(data) {
        return axios.get('/admin/collection?market=' + data.market + '&id=' + data.id);
    },
    // 编辑推荐分类
    editRecClass(data) {
        return axios.post('/zh-CN/admin/collection/update', data);
    },
    // 新增推荐分类
    addRecClass(data) {
        return axios.post('/admin/collection/create', data);
    },
    // 删除推荐分类
    delRecClass(data) {
        return axios.post('/admin/collection/delete', data);
    },
    // 推荐分类排序
    sortRecClass(data) {
        return axios.post('/zh-CN/admin/collection/sort', data);
    },
    //新增--筛选推荐list
    recAssetFilterList(data) {
        return axios.post('/admin/asset/list', data);
    },
    // 修改获取已添加的资产分类
    getAssetList(data) {
        return axios.post('/zh-CN/admin/collection/asset-list', data);
    },
    // 编辑推荐分类的资产(增加删除)
    addSortAsset(data) {
        return axios.post('/zh-CN/admin/collection/asset-sort', data);
    },
    // 启用推荐系列
    enableAsset(data) {
        return axios.post('/admin/collection/enable', data)
    },
    // 停用推荐系列
    disableAsset(data) {
        return axios.post('/admin/collection/disable', data)
    },
    // 市场资产变更
    marketAssetEdit(data){
        return axios.post('/admin/asset/edit',data)
    },
}
